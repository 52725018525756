* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Roboto';
  -webkit-user-select: none;
  --orange-color: #FF6000;
  --black-color: #000000;
  --yellow-color: rgba(255, 201, 0, 1);
  --white-color: #FFFFFF;
  --gray-1-color: #A2A2A2;
  --gray-2-color: #EEEEEE;
  --gray-3-color: #F6F6F6;
}

html {
  scroll-behavior: smooth;
}

@media screen and (prefers-reduced-motion: reduce) {
  html {
    scroll-behavior: auto;
  }
}

body::-webkit-scrollbar {
  width: 7px;
}

body::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 100px;
}

body::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 100px;
}

body::-webkit-scrollbar-thumb:hover {
  background: #555;
}

input {
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}

.container {
  background-color: #e0e0e0;
}

.gray {
  background-color: var(--gray-2-color);
}

.black-background {
  background-color: var(--black-color);
}

.white-background {
  background-color: var(--white-color);
}

.orange-background {
  background-color: var(--orange-color);
}

.gray-1-background {
  background-color: var(--gray-1-color);
}

.gray-2-background {
  background-color: var(--gray-2-color);
}

.gray-3-background {
  background-color: var(--gray-3-color);
}

.orange-color {
  color: var(--orange-color)
}

.black-color {
  color: var(--black-color)
}

.white-color {
  color: var(--white-color)
}

.gray-1-color {
  color: var(--gray-1-color)
}

.gray-2-color {
  color: var(--gray-2-color)
}

.gray-3-color {
  color: var(--gray-3-color)
}

.bold {
  font-weight: bold;
}

.main-container {
  position: relative;
  margin: auto;
  max-width: 500px;
  min-height: 100vh;
  display: flex;
  background-color: var(--white-color);
  flex-direction: column;
  overflow: hidden;
  box-shadow: 30px 0 15px -30px #424242, -30px 0 15px -30px #424242;
}

.text-strikethrough {
  text-decoration: line-through;
}

.swiper-container {
  width: 100%;
  height: 100%;
}

.margin-20-10 {
  margin: 20px 10px;
}

.alert {
  width: 90%;
  margin: 0 auto;
  background-color: var(--white-color);
  border: 1.7px solid var(--orange-color);  
  border-radius: 10px;
  padding: 20px;
  z-index: 3;
}

.alert h1 {
  text-align: center;
  margin-bottom: 20px;
  font-size: 22px;
  text-transform: capitalize;
}

.alert p {
  font-size: 13px;
  margin-bottom: 20px;
}

.alert-button-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.yes-button,
.no-button {
  width: 50%;
  margin: 0 5px;
  height: 30px;
  border: none;
  border-radius: 10px;
  font-size: 12px;
  font-weight: bold;
  background-color: var(--white-color);
}

.yes-button-full {
  width: 100%;
  margin: 0 5px;
  height: 30px;
  border: none;
  border-radius: 10px;
  font-size: 12px;
  font-weight: bold;
  background-color: var(--yellow-color);
}

.info-button {
  width: 100%;
  margin: 0 5px;
  height: 30px;
  border: none;
  border-radius: 10px;
  font-size: 12px;
  font-weight: bold;
  background-color: var(--white-color);
}

.yes-button,
.info-button,
.yes-button-full {
  background-color: var(--yellow-color);
}

.no-button {
  background-color: var(--gray-1-color);
}

.alert-button-container button:focus {
  background-color: var(--white-color);
}

.green {
  border: 1px solid green;
}

.red {
  border: 1px solid red;
}

.wrapper-gray {
  background-color: var(--gray-3-color);
  min-height: 100vh;
  width: 100%;
}

/* .swiper-slide {
  text-align: center;
  font-size: 14px;
  background: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: left;
  -ms-flex-pack: left;
  -webkit-justify-content: left;
  justify-content: left;
  -webkit-box-align: left;
  -ms-flex-align: left;
  -webkit-align-items: left;
  align-items: left;
} */

/* .swiper-slide img {
  width: 100%;
  height: 150px;
  object-fit: cover; 
} */