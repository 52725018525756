.merchant-menu-list-container-slider {
  display: flex;
  flex-direction: column;
  font-size: 12px;
  width: 136px;
  margin-right: 10px;
  justify-content: center;
  align-items: center;  
}

.merchant-menu-list-wrapper-slider {
  display: flex;
  flex-direction: column;
  background-color: var(--white-color);
}

.merchant-menu-list-header-slider {
  position: sticky;
  display: block;
  width: 136px;
  height: 136px;
  border-radius: 8px 8px 0px 0px;
}

.merchant-menu-list-header-slider img {
  display: inline-block;
  width: inherit;
  height: inherit;
  border-width: 0.5px 0.5px 0px 0.5px; /* top right bottom left */
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.05);
  border-radius: 8px 8px 0px 0px;
  object-fit: cover;
  object-position: center;
}

.merchant-menu-list-footer-slider {
  width: 136px;
  height: 112px;
  /* border: 1px solid black;   */
  display: flex;
  flex-direction: column;
  padding: 8px 8px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  box-shadow: 2px 3px 5px 1px rgba(0, 0, 0, 0.09);
  background-color: var(--white-color);
}

.merchant-menu-list-container-main {  
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  width: 136px;
  justify-content: center;
  align-items: center;
}

.merchant-menu-list-wrapper-main {
  display: flex;
  justify-content: center;
  align-items: center;
}

.merchant-menu-list-header-main {
  position: sticky;
  display: block;
  width: calc(50vw - 12px);
  height: calc(50vw - 12px);
  border-radius: 8px 8px 0px 0px;
}

.merchant-menu-list-header-main img {
  display: inline-block;
  width: calc(50vw - 11px);
  height: inherit;
  border-width: 0.5px 0.5px 0px 0.5px; /* top right bottom left */
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.05);
  border-radius: 8px 8px 0px 0px;
  object-fit: cover;
  object-position: center;
}

.merchant-menu-list-footer-main {
  width: calc(50vw - 12px);
  height: 110px;
  /* border: 1px solid black;   */
  display: flex;
  flex-direction: column;
  padding: 8px 8px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  box-shadow: 2px 3px 5px 1px rgba(0, 0, 0, 0.08);
  background-color: var(--white-color);
}

.merchant-menu-list-footer-title {
  flex: 1;
  padding: 5px 0px 15px 0px;
  font-size: 13px;  
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Number of lines to show */
  -webkit-box-orient: vertical;  
  line-clamp: 2;
  line-height: 1.2em;     
  white-space: normal;
}

.merchant-menu-list-footer-desc {
  flex: 1;
  font-size: 12px;
  margin: 3px 0px 8px 0px;
  color: var(--gray-1-color);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 10px;
}

.merchant-menu-list-wrapper-loading {
  display: flex;
  flex-direction: column;
  height: 255px;
  width: 100%;
  border-radius: 8px;
  background-color: #e2e2e2;
}

.alert-detail {
  display: flex;  
  justify-content: center;
  align-items: center;    
}

.alert-detail-plus {
  display: flex;
  font-size: 12px;
  justify-content: center;
  align-items: center;  
  margin-left: 15px;
  margin-right: 15px;  
  text-align: center;    
  color: #856404;
  background-color: #ffeeba;
  padding: 15px 10px;
  border-radius: 10px;
}

@media (min-width: 500px) {
  .merchant-menu-list-container-slider {  
    width: 166px;
  }
  
  .merchant-menu-list-header-slider {
    width: 166px;
    height: 166px;
  }
  
  .merchant-menu-list-header-slider img {
    width: inherit;
    height: inherit;
  }
  
  .merchant-menu-list-footer-slider {
    width: 166px;
    height: 112px;
  }
  
  .merchant-menu-list-container-main {  
    width: 238px;
  }
  
  .merchant-menu-list-header-main {
    width: 238px;
    height: 238px;
  }

  .merchant-menu-list-header-main img {
    width: 239px;
    height: inherit;
  }
  
  .merchant-menu-list-footer-main {
    width: 238px;
    height: 130px;
  }
}
