.discount-container {
  position: relative;
  display: flex;
  padding: 10px;
  overflow-x: scroll;
  margin-top: 20px;
}

.discount-container::-webkit-scrollbar {
    display: none;
  }

.discount-img-container {
  position: relative;
}

.discount-img {
  width: 100%;
  height: 110px;
  object-fit: cover;
  background: url(../../assets/images/item_image.png) center no-repeat;
  background-size: cover;
  border-radius: 6px;
}

.discount-img-color {
  background-color: aliceblue;
}

.overlay {
  position: absolute;
  bottom: 4px;
  height: 35px;
  width: 100%;
  background-color: var(--black-color);
  border-radius: 0 0 6px 6px;
}

.discount-card-desc {
  position: absolute;
  display: flex;
  flex-direction: column;
  bottom: 10px;
  left: 10px;
}

.discount-card-item-name,
.discount-card-item-price {
}

.discount-card-item-name {
  font-size: 10px;
  font-weight: bold;
  color: var(--orange-color);
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box !important;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  white-space: normal;
  margin-bottom: 2px;
}

.discount-card-item-price {
  font-size: 9px;
  color: var(--white-color);
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box !important;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  white-space: normal;
}

.merchant-detail-discount-card-container {
  height: 135px;
}

.merchant-detail-discount-card-desc {
  bottom: 30px;
}

.grid-container {
  display: grid;
  grid-template-columns: auto auto auto auto;
  column-gap: 20px;
}

.libido {
  background-color: rgb(177, 177, 177);
  height: 110px;
  width: 110px;
  padding: 30px 20px;
  border-radius: 10px;
}

.merchant-discount-recommend {
  padding-top: 10px;
  padding-left: 10px;
  margin-bottom: -20px;
  font-size: 14px;
  font-weight: bold;
}

.swiper-loading {
  height: 230px;
  width: 150px;
  border-radius: 8px;
  background-color: #e2e2e2;
  background-size: cover;
}

/* The loading Class */
/* .loading {
    position: relative;
    background-color: #e2e2e2;
}

.loading::after {
    display: block;
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    transform: translateX(-100%);
    background: -webkit-gradient(linear, left top,
            right top, from(transparent),
            color-stop(rgba(255, 255, 255, 0.2)),
            to(transparent));

    background: linear-gradient(90deg, transparent,
            rgba(255, 255, 255, 0.2), transparent);

    animation: loading 1.5s infinite;
}


@keyframes loading {
    100% {
        transform: translateX(100%);
    }
} */

@media (min-width: 0px) and (max-width: 280px) {
  .swiper-loading {
    height: 200px;
    width: 120px;
  }
}

@media (min-width: 281px) and (max-width: 320px) {
  .swiper-loading {
    height: 210px;
    width: 130px;
  }
}

@media (min-width: 376px) and (max-width: 424px) {
  .swiper-loading {
    height: 255px;
    width: 177px;
  }
}

@media (min-width: 425px) and (max-width: 500px) {
  .swiper-loading {
    height: 220px;
    width: 132px;
  }
}
