.item-existing-modal-container {
    width: 100%;
    max-width: 500px;
    font-size: 14px;
}

.item-existing-modal-animation-up {
    z-index: 3;
    width: inherit;
    position: fixed;
    bottom: 0;
    transition: height 400ms ease-in-out 0s;
}

.item-existing-modal-animation-down {
    z-index: 3;
    height: 0%;
    width: inherit;
    position: fixed;
    bottom: 0;
    transition: height 400ms ease-in-out 0s;
}

.item-existing-modal-animation {
   max-height: 1000px;
}

.item-existing-modal-content {    
    background-color: var(--white-color);
    min-height: 30%;
    height: 100%;
    width: 100%;
    display: flex;    
    flex-direction: column;    
    top: 0;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    scrollbar-width: 0px;
}

.item-existing-modal-header {
    padding: 20px;
}

.item-existing-modal-header span {
    font-weight: bold;  
    font-size: 18px;  
}

hr.splitter {       
    border: 1px solid #EBEBEB;
}

.item-existing-modal-body {
    padding: 20px;
}

.item-existing-modal-body-text {
    display: flex;
    flex-direction: column;
}

.item-existing-modal-body-text span {
    flex: 1;
}

.item-existing-modal-body-category-wrapper {
    display: flex;
    flex-direction: row;
}

.item-existing-modal-body-category-text {
    flex: 0;
}

.item-existing-modal-body-category-item {
    flex: 1;
}



.item-existing-modal-body-button {
    padding-top: 10px;    
    display: flex;    
}

.item-existing-modal-body-button-wrapper {
    flex: 1;
    display: flex;
}

.item-existing-button {
    border: 1px solid #EBEBEB;
    background-color: transparent;
    color: #757575;
    padding: 0px 4px;
    border-radius: 4px;      
    cursor: pointer;
}

.item-existing-button-counter-icon {
    position: relative;
    top: 1px;
    font-size: 12px;
}

.item-content-align-right {
    margin-top: 5px;
    font-weight: bold;
    display: flex;
    justify-content: flex-end;
}

.item-content-align-left {
    margin-top: 5px;
    font-weight: bold;
    display: flex;
    justify-content: flex-start;
}

.item-existing-add-number {
    display: flex;
    margin-left: 10px;
    margin-right: 10px;
    justify-content: center;
    align-self: center;
}

.item-existing-modal-body-button a {
    display: flex;
}

.item-existing-modal-footer {
    flex: 1;    
    padding: 20px;
}

.item-existing-modal-footer a {        
    display: flex;
    justify-content: center;
    align-self: center;
    align-items: center;
    width: 100%;
    padding: 10px;
    background-color: #FFC900;
    border-radius: 6px;
    border: 1px solid #FFC900;
}