/* .white-space {
    display: flex;
    height: 120px;
}

.topnav {
    position: fixed;
    top: 0;
    width: 1024px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    z-index: 999;
    padding-top: 30px;
    padding-bottom: 10px;
}

.topnav-title {
    margin-left: 30px;
    font-family: 'Oswald', sans-serif;
    font-size: 32px;
    font-weight: bold;
}

.topnav-cart-container {
    position: relative;
    display: flex;
    justify-content: center;
}

.topnav-a {
    margin: 0 30px;
    -webkit-tap-highlight-color: transparent;
}

.topnav-icon {
    width: 30px;
}

@media screen and (max-width: 1024px) {
    .topnav {
        width: 100%;
    }
} */

/* .white-space {
    display: flex;
    height: 120px;
}

.topnav-container {
    position: fixed;
    top: 0;
    width: 100%;
    height: 120px;
    display: flex;
    background-color: #fff;
    z-index: 999;
}

.homepage {
    align-items: center;
    justify-content: space-between;
}

.homepage-topnav-left-side {
    display: flex;
    height: 100%;
    padding-left: 20px;
    align-items: center;
    justify-content: center;
}

.topnav-left-side {
    display: flex;
    width: 10%;
    height: 100%;
    align-items: center;
    justify-content: center;
}

.topnav-center-side {
    display: flex;
    width: 80%;
    height: 100%;
    align-items: center;
    justify-content: center;
}

.topnav-title-text {
    font-size: 30px;
    font-weight: bold;
    font-family: 'Oswald', sans-serif;
}

.topnav-right-side {
    display: flex;
    width: 10%;
    height: 100%;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    color: #000;
}

.homepage-topnav-title-text {
    font-family: 'Oswald', sans-serif;
    font-size: 32px;
    font-weight: bold;
    text-transform: capitalize;
}

.topnav-icon {
    font-size: 34px;
    cursor: pointer;
}

.homepage-icon-margin {
    margin-right: 20px;
}

@media screen and (max-width: 1024px) {
    .topnav-container {
        width: 100%;
    }
}

@media screen and (max-width: 425px) {
    .topnav-container {
        height: 90px;
    }

    .white-space {
        height: 90px;
    }

    .topnav-left-side {
        width: 15%;
    }

    .topnav-center-side {
        width: 70%;
    }

    .topnav-title-text {
        font-size: 26px;
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box !important;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        white-space: normal;
    }

    .topnav-right-side {
        width: 15%;
    }

    .homepage-topnav-title-text {
        font-size: 28px;
    }

    .topnav-icon {
        font-size: 27px;
    }

    .homepage-icon-margin {
        margin-right: 35px;
    }
} */

.topnav-container {
    position: fixed;
    display: flex;
    top: 0;
    height: 60px;
    width: 500px;
    align-items: center;
    justify-content: space-between;
    background-color: var(--gray-color);
    z-index: 2;
}

.topnav-history-container {
    position: fixed;
    display: flex;
    top: 0;
    height: 60px;
    width: 500px;
    align-items: center;
    justify-content: space-between;
    background-color: var(--white-color);
    z-index: 2;
}

.category-topnav-container {
    position: relative;
    display: flex;
    height: 60px;
    width: 500px;
    align-items: center;
    justify-content: space-between;
    background-color: var(--white-color);
    z-index: 2;
}

.white-space {
    display: flex;
    height: 60px;
}

.relative-position {
    position: relative;
}

.homepage-topnav,
.receipt-topnav {
    display: flex;
    height: 60px;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
}

.homepage-topnav-title,
.receipt-topnav-title {
    font-weight: bold;
    font-size: 20px;
    cursor: pointer;
}

.homepage-cart-container {
    cursor: pointer;
}

.homepage-button-container {
    background-color: aliceblue;
    flex: 1;
    cursor: pointer;
}


.topnav-left-side,
.topnav-center-side,
.topnav-right-side {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
}

.topnav-left-side {
    width: 15%;
    /* background-color: aqua; */
}

.none {
    position: relative;
    top: -60px;
    display: flex;
    width: 70%;
    justify-content: flex-start;
    -webkit-animation: moveUp .2s ease-out forwards;
}

.topnav-center-side {
    position: relative;
    display: flex;
    width: 70%;
    justify-content: flex-start;
}

.merchant-detail-topnav-center-side {
    position: relative;
    display: flex;
    width: 70%;
    justify-content: flex-start;
    -webkit-animation: moveDown .2s ease-out forwards;
}

.topnav-scrolled {
    -webkit-animation: fadeinDown .2s ease-out forwards;
}

.topnav-right-side {
    width: 15%;
    /* background-color: antiquewhite; */
}

.topnav-title-text {
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 1px;
}

.topnav-input {
    width: 100%;
    height: 100%;
    background: transparent;
    border: none;
}

.topnav-input:focus {
    background: transparent;
    border: none;
    outline: 0;
}

.background-white {
    background-color: white;
}

.margin-left-10 {
    margin-left: 10px;
}

.backgroundGray {
    background-color: gray;
}

/* style top-nav new version */
.home-page-topnav-container-title-deliver p {
    color: #6e93a5;
}

.homepage-topnav-container-title-address {
    font-weight: bold;
    
}

.homepage-topnav-container-title-address span {
    width : 200px;
    overflow:hidden;
    display:inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.homepage-topnav-container {
    flex: 1;
    display: flex;
    flex-direction: row;
}

.home-page-topnav-container-title {
    flex: 6;
    display: flex;
    flex-direction: column;
    color: #517C92;
    font-size: 12px;
}

.homepage-topnav-container-button {
    flex: 1;
    /* background-color: aliceblue; */
}

.home-page-topnav-container-icon {
    flex: 1;
}

.homepage-topnav-container-button span {
    height: 30px;
    width: 30px;
    padding: 6px;
    float: right;
    border-radius: 32px;
    font-size: 14px;
    background-color: #FFC900;
    cursor: pointer;
}

.homepage-topnav-container-button span p {
    display: flex;
    justify-content: center;
}

@keyframes moveDown {
    from {
        top: -60px;
    }

    to {
        top: 0px;
    }
}

@keyframes moveUp {
    from {
        top: 0px;
    }

    to {
        top: -60px;
    }
}

@keyframes fadeInDown {
    0% {
        opacity: 0;
        transform: translateY(-40px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@media screen and (max-width: 500px) {
    .topnav-container {
        width: 100%;
    }

    .topnav-history-container {
        width: 100%;
    }
}