.category-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: nowrap;
    padding: 20px 0px;
}

.category-card {    
    -webkit-tap-highlight-color: transparent;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    width: 90px;
    text-align: center;
    margin-top: 10px;
}

.category-img {
    width: 67px;
    height: 67px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 10px;
    background: url(../../assets/images/category_image.svg);
    background-size: cover;
    box-shadow: 0px 0px 7px 7px #F5F5F5;
}

.category-name {
    color: #000;
    padding: 2px 5px;
    font-weight: bold;
    font-size: 12px;
}

.category-loading {    
    height: 67px;
    width: 67px;
    border-radius: 50%;
    background-color: #e2e2e2;
    background-size: cover;
}

