.search-input-container {
    position: relative;
    display: flex;
    width: 100%;
    padding: 0px 16px;
    /* background-color: white; */
}

.search-input {
    width: 100%;
    height: 56px;
    font-size: 16px;
    border: 2px solid;
    border-color: #F5F5F5;
    border-radius: 10px;
    padding: 0px 16px;
}

.search-input-button-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

.search-input-button {
    position: absolute;
    margin-right: 50px;
}

.explore-search-box {
    border: none;
    border-radius: 0px;
    padding: 0 10px;
}

.merchant-detail-search {
    background-color: var(--gray-2-color);
    border: 1px solid var(--gray-1-color);
    color: var(--black-color);
    font-family: 'Roboto', sans-serif;
}

.search-icon {
    width: 25px;
    height: 25px;
    position: absolute;
    color: var(--gray-1-color);
    cursor: pointer;
}

.search-input:focus {
    outline: none;
}