/* .bottomnav-container {
    position: fixed;
    width: 990px;
    margin: 0 auto;
    left: 0;
    right: 0;
    bottom: 20px;
    display: flex;
    align-items: center;
    height: 60px;
    background-color: #fff;
    border-radius: 13px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    z-index: 999;
}

.bottomnav-ul {
    display: flex;
    flex-direction: row;
    list-style: none;
    width: 100%;
    justify-content: space-around;
}

.bottomnav-a {
    -webkit-tap-highlight-color: transparent;
    text-decoration: none;
    color: #000;
    text-align: center;
    font-size: 14px;
    font-weight: bold;
    color: #424242;
}

.bottomnav-icons {
    display: block;
    width: 25px;
}

.active {
    color: #FFC00E;
    font-weight: bold;
}

.bottom-space {
    height: 85px;
}

@media screen and (max-width: 1024px) {
    .bottomnav-container {
        width: 95%;
    }
}

@media screen and (max-width: 320px) {
    .bottomnav-container {
        width: 95%;
    }

    .bottomnav-a {
        font-size: 10px;
    }
} */

.background-button-white {
    background-color: black;
    /* background-color: var(--white-color); */
}

.button-confirm {
    background-color: #FFC900;
    border: 0px;
    padding: 15px;
    border-radius: 8px;
    width: 100%;
    margin-left: 20px;
    margin-right: 20px;
    font-size: 14px;
    align-self: center;
}

.bottomnav-content {
    height: 117px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    background-color: var(--white-color);
    margin-bottom: 60px;
}

/* bottomnav payment */
.bottomnav-payment-wrapper {
    flex: 1;
    display: flex;
    flex-direction: row;
    padding-left: 20px;
    padding-right: 20px;
    /* background-color: #FFC900; */
}

.bottomnav-payment-wrapper-icon {
    flex: 1;
    display: flex;
    align-self: center;
    /* background-color: #FFC900; */
}

.bottomnav-payment-wrapper-name {
    flex: 5;
    display: flex;
    flex-direction: column;
    align-self: center;
    font-size: 12px;
    /* background-color: antiquewhite; */
}

.bottomnav-payment-wrapper-dot {
    flex: 1;
    display: flex;
    justify-content: flex-end;
}

.bottomnav-payment-wrapper-text {
    flex: 1;
    font-size: 12px;
    font-weight: bold;
}

.bottomnav-payment-wrapper-icon-right {
    flex: 1;
    /* align-self: center; */
    /* background-color: aqua; */
}

/* bottomnav button */
.bottomnav-button-wrapper {
    flex: 1;
    display: flex;
    justify-content: center;
    /* padding-top: 10px; */
    padding-bottom: 20px;
    /* background-color: aliceblue; */
}

.loader-table {
    border: 4px solid #f3f3f3;
    border-radius: 50%;
    border-top: 4px solid #555555;
    width: 15px;
    height: 15px;
    -webkit-animation: spin 2s linear infinite;
    /* Safari */
    animation: spin 2s linear infinite;
}

/* modal */
.modal-open {
    /* display: block; */
    position: fixed;
    top: 0;
    width: inherit;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
}

/* .modal-payment-wrapper {
    height: auto;
    position: fixed;
    bottom: 0;
    padding: 20px;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    padding-bottom: 40px;
    width: inherit;
    background-color: #fefefe;
    margin-bottom: 50px;
    font-size: 13px;
} */

.modal-payment-wrapper-open {
    padding: 20px;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    padding-bottom: 40px;
    background-color: #fefefe;
    margin-bottom: 50px;
    font-size: 13px;
    /* z-index: 3; */
    height: 40%;
    /* width: inherit; */
    /* position: fixed; */
    /* bottom: 0; */
    /* transition: height 400ms ease-in-out 0s; */
}

.modal-payment-wrapper-close {
    padding: 20px;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    padding-bottom: 40px;
    background-color: #fefefe;
    font-size: 13px;
    /* z-index: 3; */
    /* height: 0%; */
    /* width: inherit; */
    /* position: fixed; */
    /* bottom: 0; */
    /* transition: height 400ms ease-in-out 0s; */
}
.transitionUp {
    z-index: 3;
    width: inherit;
    position: fixed;
    bottom: 0;
    transition: height 400ms ease-in-out 0s;
}

.transitionDown {
    z-index: 3;
    height: 0%;
    width: inherit;
    position: fixed;
    bottom: 0;
    transition: height 400ms ease-in-out 0s;
}

.bottomNav-modal-container-open {
    height: 100%;
    width: 500px;
    position: fixed;
    z-index: 2;
    background-color: rgba(0, 0, 0, 0.5);
    /* overflow: hidden; */
    /* transition: opacity 5000ms ease 0s; */
}
.bottomNav-modal-container-close {
    height: 100%;
    width: 500px;
    position: fixed;
    z-index: 2;
    background-color: rgba(0, 0, 0, 0.5);
    visibility: hidden;
    /* transition: opacity 5000ms ease 0s; */
}
.modal-payment-wrapper-header {}

.modal-payment-wrapper-header-close {
    color: gray;
    font-size: 24px;
    float: right;
}

.modal-payment-wrapper-content-scrollable-cash {
    display: flex;
    flex-direction: column;
    padding-top: 10px;
    padding-bottom: 10px;
}

.modal-payment-wrapper-content-scrollable-header {
    flex: 1;
}

.modal-payment-wrapper-contents-scrollable-content {
    flex: 1;
    padding-top: 20px;
    display: flex;
    flex-direction: row;
    cursor: pointer;
}

.modal-payment-wrapper-contents-scrollable-content-icon {
    flex: 1.5;
    display: flex;
    align-items: center;
}

.modal-payment-wrapper-contents-scrollable-content-icon img {
    width: 30px;
}

.modal-payment-wrapper-contents-scrollable-content-text-wrapper {
    flex: 8;
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.modal-payment-wrapper-contents-scrollable-content-text {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.modal-payment-wrapper-contents-scrollable-content-text .modal-payment-wrapper-contents-scrollable-content-text-bottom {
    line-height: 1.8;
}

.modal-payment-wrapper-contents-scrollable-content-radio {
    flex: 1;
}

.modal-payment-wrapper-contents-scrollable-content-radio input {
    float: right;
    margin: 10px 10px 0px 0px;
}

.modal-payment-wrapper-contents-scrollable-content-radio button {
    border: none;
    background-color: rgba(255, 97, 0, 0.3);
    ;
    font-size: 14px;
    padding: 5px;
    border-radius: 32px;
    float: right;
}

.modal-payment-wrapper-content-scrollable {
    width: 100%;
    height: 100%;
    overflow-y: scroll;
}

.modal-payment-wrapper-content-scrollable::-webkit-scrollbar {
    width: 0 !important;
}


@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.bottomnav-wrapper {
    position: fixed;
    width: 500px;
    bottom: 0px;
    /* padding: 10px 0; */
    z-index: 2;
}

.bottomnav-container {
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    box-shadow: 0px -3px 6px rgba(0, 0, 0, 0.06);
    /* border-radius: 10px; */
    /* margin: 0 10px; */
    padding: 0px 0px;
    background-color: var(--white-color);
}

.bottomnav-link {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: bold;
    font-size: 10px;
    text-decoration: none;
    color: var(--gray-1-color);
}

.bottomnav-payment-text {
    display: flex;
    font-size: 16px;
}

.active,
.active img {
    color: black;
}

.bottom-space {
    height: 150px;
    background-color: transparent;
}

.gray-bottom-space {
    height: 200px;
    background-color: var(--gray-3-color);
}

.gray-bottom-space-history {
    height: 50px;
    background-color: #FAFAFA;
}

/* bottomnav payment */
.button-confirm {
    background-color: #FFC900;
    border: 0px;
    padding: 15px;
    border-radius: 8px;
    width: 90%;
    font-size: 14px;
    align-self: center;
}

.bottomnav-content {
    height: 120px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    background-color: var(--white-color);
}

.bottomnav-payment {
    display: flex;
    flex-direction: row;
    margin: 0px 5% 0px 5%;
    margin-bottom: -20px;
    /* background-color: #555555; */
}

.bottomnav-payment-icon {
    display: flex;
    flex: 1;
    align-self: center;
}

.bottomnav-payment-icon-right {
    flex: 1;
    align-self: right;
    /* background-color: aqua; */
}

.bottomnav-payment-name {
    display: flex;
    flex: 5;
    /* justify-content: center; */
    align-self: center;
}

.loader-table {
    border: 6px solid #f3f3f3;
    border-radius: 50%;
    border-top: 6px solid #555555;
    width: 25px;
    height: 25px;
    -webkit-animation: spin 2s linear infinite;
    /* Safari */
    animation: spin 2s linear infinite;
}


@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@media screen and (max-width: 500px) {
    .bottomnav-wrapper {
        width: 100%;
    }
}