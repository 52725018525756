/* root history */
.history-container {
    min-height: 100vh;
    background-color: #FAFAFA;
    padding: 20px 8px;
}

/* card history */
.history-card-new {
    background-color: #FFFFFF;
    padding: 10px;    
    margin: 0px 0px 10px 0px;
    border-radius: 6px;
    box-shadow: 2px 3px 5px 1px rgba(0, 0, 0, 0.08);    
}
/* - end of card history -*/

/* card history body */
.history-card-body {
    display: flex;
    flex-direction: column;
}
/* - end of history body - */

/* card history header */
.history-card-header {
    flex: 1;
    padding: 10px 0px;
    display:flex;
    flex-direction: row;    
    font-size: 16px;

}

.history-card-header-left {
    flex: 3;    
    overflow: hidden;
    text-overflow: ellipsis;
}

.history-card-header-right {
    flex: 2;
    display: flex;
    justify-content: flex-end;    
}

.history-card-header-right span {
    flex: 1;    
    display: flex;
    justify-content: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
/* - end of history header - */

/* card history content */
.history-card-content {
    flex: 1;
    display: grid;
    grid-template-columns: 20% 80%;    
    padding: 10px 0px;    
}

.history-card-content-border {
    width: 100%;
    height: 2px;
    background-color: var(--gray-2-color);
    margin: 10px 0px;    
}

.history-card-content-border-vertical {
    width: 2px; /* Set the width to create a vertical line */
    height: 100%; /* Set the desired height of the vertical line */
    background-color: var(--gray-2-color);
    margin: 0px 10px;
}

.history-card-content-image {
    flex: 1;
    display: flex;    
    align-self: center;
}

.history-card-content-image img {    
    border-radius: 8px;
}

.history-card-content-text {
    flex: 5;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: center;
    font-size: 16px;
    line-height: 1.5;
}

.history-card-content-etc {
    display: flex;
    font-size: 10px;    
}

.history-card-content-etc-1 {
    width: 80px;
    /* background-color: antiquewhite;     */
    display: flex;
    /* justify-content: center; */
    align-self: center;
}
/* - end of card content - */

/* - end of root history - */

.history-card {
    margin-bottom: 15px;
    padding: 10px 20px;
}

.white { 
    background-color: var(--white-color);
}

.orange { 
    background-color: #FFC900;
}



.history-card:first-child {
    margin-top: 15px;
}

.history-card:last-child {
    margin-bottom: 0;
}

.history-item-container {
    display: flex;
}

.history-detail-image-container {
    width: 80px;
    height: 80px;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
}

.history-detail-image-container img {
    width: 80px;
    height: 80px;
    object-fit: cover;
    background: url(../../assets/images/merchant-image.png) center no-repeat;
    background-size: cover;
    border-radius: 10px;
}

.history-detail {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100px;
}

.history-detail-name,
.history-detail-days {
    margin-bottom: 10px;
}

.history-detail-name {
    font-size: 12px;
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    display: -webkit-box !important;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.history-detail-days {
    font-size: 10px;
    color: var(--gray-1-color)
}

.history-detail-item-status {
    display: flex;
    justify-content: space-between;
}

.history-detail-item,
.history-detail-status {
    font-size: 12px;
}

.rating {
    display: flex;
    justify-content: center;
    width: 100%;
    font-size: 35px;
    margin-top: 0px;
}

.new-rating {
    padding: 10px 0px 15px 0px;
    display: flex;
}

.new-rating img {
    flex: 1;
}

.rating-text {
    font-size: 14px;
    color: #969696;
}

#rating-icon {
    margin: 0 8px;
}


.filled {
    color: var(--orange-color);
}

@media (min-width: 0px) and (max-width: 320px) {
    .history-card-content {
        flex: 1;
        display: grid;
        grid-template-columns: 25% 75%;    
        padding: 10px 0px;    
    }
}

.link-style {
    text-decoration: none; /* Remove the underline */
    color: inherit; /* Inherit the color from the parent element */
  }