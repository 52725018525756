.merchant-detail-button-container {
    position: fixed;
    margin-bottom: 60px;
    height: 60px;
    display: flex;
    bottom: 0px;
    background-color: #FFC900;
    width: 500px;
    align-items: center;
    /* justify-content: space-between; */
    z-index: 2;
    cursor: pointer;
}

.merchant-detail-button-container-count-item {
    flex: 1;
}

.merchant-detail-button-container-info {
    flex: 1;        
}

.merchant-detail-item-selected {
    margin-left: 20px;
    color: var(--black-color);
    font-size: 14px;
    font-weight: bold;
}

.merchant-detail-button-container-into-wrap {
    /* background-color: antiquewhite; */
    display: flex;
    flex-direction: row;    
    float: right;
}

.merchant-detail-button-container-into-wrap img {
    /* background-color: antiquewhite; */
    margin-right: 20px;
    margin-left: 10px;
}

.merchant-detail-button {    
    /* background-color: antiquewhite; */
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: bold;
    /* margin-right: 20px;
    color: var(--black-color);
    font-size: 14px;
    font-weight: bold;
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px;
    width: 140px;
    height: 100%; */
}



.merchant-detail-icon {
    position: relative;
}

.item-detail-button-container,
.button-orange-container {
    position: relative;
    display: flex;
    flex-direction: column;
}

.item-detail-button-container button,
.button-orange-container button {
    position: fixed;
    bottom: 0;
    width: 500px;
    height: 60px;
    border: none;
    background-color: var(--orange-color);
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
    z-index: 2;
}

.button-orange-container button {
    text-transform: uppercase;
}

@keyframes moveUp {
    from {
        display: none;
    }

    to {
        display: flex;
    }
}

@media screen and (max-width: 500px) {
    .merchant-detail-button-container,
    .button-orange-container {
        margin-bottom: 60px;
        width: 100%;
    }

    .item-detail-button-container button,
    .button-orange-container button {
        width: 100%;
    }
}