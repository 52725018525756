.cart-container {
  padding: 20px;
}

.cart-card {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 30px;
}

.cart-card img {
  width: 80px;
  height: 80px;
  object-fit: cover;
  margin-right: 20px;
}

.cart-text-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 80px;
  border-bottom: 2px solid #e0e0e0;
  padding-top: 5px;
}

.cart-merchant-name,
.cart-items {
  height: 100%;
  color: var(--black-color);
  text-transform: capitalize;
}

.cart-merchant-name {
  font-size: 18px;
}

.cart-items {
  font-size: 14px;
}

.cart-detail-container {
  background-color: var(--gray-3-color);
  /* min-height: 100vh; */
}

.cart-detail-main-content {
  /* padding: 20px 0 0 0; */
  display: flex;
  flex-direction: column;
}

.items-in-cart-title {
  border-bottom: 2px solid #e0e0e0;
  padding: 20px 0 0 10px;
  background-color: var(--white-color);
}

.items-in-cart-title span {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;
  color: var(--black-color);
}

.items-in-cart-space-top {
  padding: 10px 0px 5px 0px;
  background-color: var(--white-color);
}

.items-in-cart-container {
  display: flex;
  flex-direction: column;
  padding: 0 10px;
  background-color: var(--white-color);
}

.items-wrapper {
  display: flex;
  flex-direction: row;
}

.items-in-cart {
  padding: 10px 0;
  margin-bottom: 10px;
  border-bottom: 2px solid var(--gray-2-color);
  /* background-color: #e7e6e6; */
}

.items-in-cart-main {
  display: flex;
  flex-direction: row;
}

.items-in-cart-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
  z-index: 1; /* Ensure the overlay is above other content */
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 1.5em;
  pointer-events: none;
}

.items-in-cart-addon {
  /* background-color: #FFC900; */
  display: flex;
  flex-direction: row;
  margin-top: 5px;
}

.items-in-cart-addon-desc-container-margin-left-7 {
  margin-left: 7px;
  /* background-color: red; */
}

.items-in-cart-flex-1 {
  flex: 1;
}

.items-in-cart-flex-2 {
  flex: 2;
}

.items-in-cart-flex-3 {
  flex: 3;
}

.items-in-cart-flex-4 {
  flex: 4;
}

.items-in-cart-flex-5 {
  flex: 5;
}

.items-in-cart-flex-6 {
  flex: 6;
}

.items-in-cart-flex-7 {
  flex: 7;
}

.items-in-cart-flex-8 {
  flex: 8;
}

.items-in-cart-flex-9 {
  flex: 9;
}

.items-in-cart-flex-10 {
  flex: 10;
}

.items-in-cart:last-child {
  border-bottom: none;
}

.items-in-cart-icon-container {
  /* position: relative; */
  top: 3px;
  margin-right: 10px;
}

.items-in-cart-icon-container-image {
  border-radius: 4px;
}

.items-in-cart-name-container-text-unavailable {
  display: flex;
  justify-content: center;
  font-size: 12px;
  color: #f50057;
  margin-bottom: 10px;
}

.items-in-cart-name-container {
  display: flex;
  flex-direction: column;
}

.items-in-cart-name-container-button-unavailable {
  width: 40px;
  display: flex;
  justify-content: center;
  text-decoration: none;
  align-self: center;  
  padding: 3px;
  border: 2px solid #f3bc09;
  border-radius: 4px;
  background-color: #f3bc09;  
}

.items-in-cart-icon-container-wrapper {
  position: relative;
  width: 52px; /* Width of the image */
  height: 52px; /* Height of the image */
  margin-bottom: 5px;
}

.items-in-cart-icon-container-image-unavailable {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  filter: grayscale(100%);
  transition: filter 0.3s;    
}

.items-in-cart-container-unavailable-btn-remove {
  padding: 5px;
  margin-top: 5px;
  border: 1px solid #f3bc09;
  border-radius: 8px;
  font-size: 12px;
  background-color: #f3bc09;
  color: #000000;
}

.items-in-cart-icon-container-image-notavailable::before {
  position: absolute;
  background: rgba(136, 136, 136, 0.5); /* Transparent color */
  border-radius: 4px; /* If you want rounded corners */
}

.items-in-cart-name {
  font-size: 14px;
  color: var(--black-color);
  font-weight: bold;
}

.items-in-cart-notes-container {
  margin-top: 5px;
}

.items-in-cart-addon-desc-container {
  display: flex;
  flex-direction: column;
  font-size: 14px;
}

.items-in-cart-addon-desc-container-item {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  line-height: 2;
}

.items-in-cart-addon-title {
  font-size: 12px;
  color: var(--black-color);
  font-weight: bold;
}

.items-in-cart-addon-desc-wrapper {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
}

.items-in-cart-addon-desc {
  font-size: 12px;
  text-align: justify;
  word-break: break-all;
  color: var(--gray-1-color);
}

.items-in-cart-addon-edit {
  display: flex;
}

.items-in-cart-addon-edit-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  width: auto;
  border: 1px solid #ebebeb;
  padding: 3px;
}

.items-in-cart-addon-edit-icon {
  display: flex;
  justify-content: center;
  align-self: center;
  font-size: 16px;
}

.items-in-cart-addon-edit-icon img {
  height: 16px;
  width: 16px;
  color: #424242;
}

.items-in-cart-addon-edit-text {
  font-size: 12px;
  color: #757575;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
}

.merchant-menu-list-header-card-price-wrapper {
  display: flex;
  justify-content: right;
}

.items-in-cart-notes-title,
.items-in-cart-notes {
  font-size: 10px;
  color: var(--gray-1-color);
}

.edit-notes {
  border: 1px solid var(--gray-1-color);
  padding: 2px;
  border-radius: 5px;
  font-size: 10px;
}

.edit-notes:focus {
  outline: none;
}

.items-in-cart-counted-container {
  width: 30%;
  text-align: center;
}

.items-in-cart-counted {
  font-size: 12px;
  margin: 0 5px;
}

.button-detail-trash {
  font-size: 18px;
}

.cart-detail-button-counter {
  border: 1px solid #ebebeb;
  background-color: transparent;
  color: #757575;
  padding: 0 4px;
  border-radius: 4px;
}

.cart-detail-button-trash {
  border: 1px solid #ebebeb;
  background-color: transparent;
  color: #757575;
  padding: 4px 5px;
  border-radius: 4px;
}

.items-in-cart-price-container {
  text-align: right;
}

.items-in-cart-trash-container {
  display: flex;
  justify-content: right;
  align-items: center;
}

.items-in-cart-price {
  font-size: 12px;
}

.items-in-cart-price-discount {
  font-size: 12px;
  text-decoration: line-through;
}

.comment-input-container {
  display: flex;
  padding: 0px 10px 25px 10px;
  background-color: var(--white-color);
}

.comment-input-container input {
  width: 100%;
  height: 50px;
  padding: 5px;
  color: var(--black-color);
  border-width: 0px 0px 1px 0px;
  border-color: var(--gray-2-color);
  font-size: 14px;
}

.comment-input-container input:focus {
  outline: none;
}

.bill-detail-wrapper {
  position: relative;
  width: 100%;
  padding-top: 10px;
}

.bill-detail-container {
  position: relative;
  /* background-image: url(../../assets/images/bill.svg); */
  background-color: var(--white-color);
  background-repeat: no-repeat;
  background-size: 100%;
  margin: 15px;
  padding: 16px;
  border-radius: 8px;
  border-style: solid;
  border-width: 1px;
  border-color: #ffc900;
}

.bill-detail {
  width: 100%;
  padding-bottom: 4px;
  margin-bottom: 4%;
}

.bill-detail span {
  text-transform: uppercase;
  font-weight: bold;
  color: var(--black-color);
  font-size: 14px;
}

.bill-detail-content {
  display: flex;
  justify-content: space-between;
  margin-bottom: 3%;
  /* border-bottom: 1px solid #e0e0e0; */
  padding-bottom: 4px;
  color: #424242;
  text-transform: capitalize;
  font-size: 12px;
}

.bill-detail-content:last-child {
  margin-bottom: 0;
}

.red-color {
  color: #e50f0f;
}

.to-pay span {
  font-weight: bold;
}

.merchant-discount-content-container {
  margin-left: 10px;
  margin-right: 10px;
}

/* The loading Class */
.loading-cart {
  width: 100%;
  position: relative;
  background-color: #dddbdd;
}

/* The moving element */
.loading-cart::after {
  display: inline-block;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  font-size: 2em;
  font-weight: bold;
  transform: translateX(-100%);
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(transparent),
    color-stop(rgba(255, 255, 255, 0.2)),
    to(transparent)
  );

  background: linear-gradient(
    90deg,
    transparent,
    rgba(255, 255, 255, 0.2),
    transparent
  );

  /* Adding animation */
  animation: loading 2s infinite;
}

.modal-cart-notes-detail textarea {
  resize: none;
  /* padding: 0.375rem 0.75rem; */
  padding: 10px;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.modal-cart-notes-detail {
  width: 100%;
  /* margin: 0 auto; */
  background-color: var(--white-color);
  /* border: 1.7px solid var(--orange-color); */
  border-radius: 8px;
  padding: 15px;
  z-index: 3;
}

.modal-cart-notes-detail-button {
  display: flex;
  flex-direction: row;
}

.modal-cart-notes-detail-button-yes {
  flex: 1;
  margin: 5px;
  padding: 5px;
  border: 1px;
  border-radius: 8px;
  background-color: #ffc900;
}

.modal-cart-notes-detail-button-no {
  flex: 1;
  margin: 5px;
  padding: 5px;
  border: 1px;
  border-radius: 8px;
  /* background-color: #FFC900; */
}

/* Loading Animation */
@keyframes loading-cart {
  100% {
    transform: translateX(100%);
  }
}

@media screen and (max-width: 374px) {
  .bill-detail {
    margin-bottom: 2%;
  }

  .bill-detail span {
    font-size: 11px;
  }

  .bill-detail-content {
    font-size: 10px;
    margin-bottom: 5px;
  }
}

@media screen and (min-width: 500px) {
  .cart-detail-container {
    background-color: var(--gray-3-color);
    min-height: 100vh;
  }

  .bill-detail {
    margin-bottom: 2%;
  }

  .bill-detail span {
    font-size: 11px;
  }

  .bill-detail-content {
    font-size: 10px;
    margin-bottom: 5px;
  }
}
