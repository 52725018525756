.merchant-menu-list-addon-container {
    font-size: 16px;
    height: 100%;    
    padding-bottom: 120px; 
    width: inherit;           
}

.merchant-menu-list-header-card {
    width: 500px;
    background-color: white;
    z-index: 999;
    position: fixed;
    display: flex;
    height: 60px;            
    padding-left: 10px;
    padding-right: 10px;
    border-bottom: 1px solid #EBEBEB;    
}

.merchant-menu-list-addon-space {
    margin-top: 60px;
}

.merchant-menu-list-header-card-button {
    flex: 1;    
    display: flex;
    align-self:center;
}

.merchant-menu-list-header-card-name {
    flex: 7;    
    display: flex;        
    align-self: center;
    /* background-color: aqua; */
}

.merchant-menu-list-header-card-price {
    flex: 1;
    display: flex;
    align-self:center;    
}

.merchant-menu-list-header-card-price a {
    float: right;
}

.merchant-menu-list-card {    
    display: flex;
    flex-direction: column;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
}

.merchant-menu-list-card-wrapper {
    border: 1px solid #EBEBEB;
    border-radius: 8px;
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 10px;
}

.merchant-menu-list-card-title {
    display: flex;
    flex-direction: column;
    padding-top: 10px;
    padding-bottom: 10px;
}

.merchant-menu-list-card-title-main {
    flex: 1;
}

.merchant-menu-list-card-title-submain {
    /* padding: 0px 0px px 0px; */
}

.merchant-menu-list-card-note-submain {
    padding: 0px 0px 10px 0px;
}

.merchant-menu-list-card-item-desc {
    position: relative;
    padding: 15px 0 0;    
    width: calc(100%-16px);
}

.input-field-desc:placeholder-shown~.input-label-desc {    
    font-size: 1.3rem;
    cursor: text;
    top: 20px;
}

.input-field-desc {
    font-family: inherit;
    width: 100%;
    border: 0;
    border-bottom: 2px solid #9b9b9b;
    outline: 0;
    font-size: 16px;
    font-weight: 400;
    /* color: black; */
    padding: 7px 0;
    /* padding-left: 8px; */
    background: transparent;
    transition: border-color 0.2s;
}

.input-field-desc::placeholder {
    color: transparent;
}

.input-label-desc {    
    position: absolute;
    top: 0;
    display: block;
    transition: 0.2s;
    font-size: 12px !important;
    color: #969696;
    font-weight: 400;    
}

.input-field-desc:focus {    
    padding-bottom: 6px;
    font-weight: 400;
    border-width: 3px;
    border-image: linear-gradient(to right, #517C92, #517C92);
    border-image-slice: 1;
}

.input-field-desc:focus~.input-label-desc {
    position: absolute;
    top: 0;
    display: block;
    transition: 0.2s;
    font-size: 12px;
    color: #517C92;
    font-weight: 400;
}

/* reset input */
.input-field-desc:required,
.input-field-desc:invalid {
    box-shadow: none;
}

.merchant-menu-list-card-item {
    display: flex;
}

.merchant-menu-list-card-item-name {
    flex: 8;
    /* background-color: antiquewhite; */
}

.merchant-menu-list-card-item-text {
    flex: 2;
    /* background-color: aliceblue; */
}

.merchant-menu-list-card-item-text-empty a {
    color: #f16767;
}

.merchant-menu-list-card-item-text a {
    float: right;
}

.merchant-menu-list-card-item-checkbox {
    flex: 1;
    justify-content: center;
    align-self: center;
    /* background-color: azure; */
}

.merchant-menu-list-card-item-checkbox input {
    float: right;
}

.merchant-menu-list-card-vertical {
    padding-top: 15px;
    padding-bottom: 15px;
}

.merchant-menu-list-card-item-note {
    margin-top: 5px;
    width: 100%;
    padding: 5px;
    border: 1px solid gray;
    border-radius: 8px;
}

.merchant-menu-list-card-bottom {        
    position: fixed;
    width: 500px;    
    background-color: white;    
    border-top: 1px solid #EBEBEB;    
    padding: 15px;
    bottom: 0;
}

.merchant-menu-list-card-bottom-header {
    display: flex;
}

.merchant-menu-list-card-bottom-quantity {
    flex: 1;
    justify-content: center;
    align-self: center;
}

.merchant-menu-list-card-bottom-button {
    flex: 1;
    justify-content: center;
    align-self: center;
}

.merchant-menu-list-card-bottom-button-right {
    float:right;
}

.merchant-menu-list-card-bottom-button-right button {   
    cursor: pointer;     
    background-color: white;     
    width: 30px;    
    height: 30px;
    /* border: 1px solid orange; */
    /* border-radius: 16px; */
}

.merchant-menu-list-card-bottom-big-button button{
    cursor: pointer;
    margin-top: 10px;
    width: 100%;
    padding: 5px;
    border: 1px solid orange;
    border-radius: 8px;
    background-color: orange;
}

@media (min-width:0px) and (max-width:500px) {
    .merchant-menu-list-card-bottom {        
        width: 100%;
    }

    .merchant-menu-list-header-card {
        width: 100%;
    }
}

