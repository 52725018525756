.merchant-list-container {}

#scrollbar::-webkit-scrollbar {
    width: 7px;
}

#scrollbar::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 100px;
}

#scrollbar::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 100px;
}

#scrollbar::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.merchant-list-count {
    text-align: right;
    margin-bottom: 20px;
    font-weight: bold;
    font-size: 12px;
    padding: 20px 20px 0 20px;
}

.component-card-loading {
    overflow: hidden;
    width: inherit;
}

/* Card title */
.component-title {
    padding: 8px;
    font-size: 22px;
    font-weight: 700;
}

.component-title-loading {
    height: 10px
}

.component-title-loading.loading {
    height: 1rem;
    margin: 0rem 1rem;
    border-radius: 3px;
}

.component-description {
    padding: 8px;
    font-size: 16px;
}

.component-description-loading {
    height: 10px;
}

.component-description-loading.loading {
    height: 1rem;
    margin: 1rem;
    border-radius: 3px;
}

/* The loading Class */
.loading {
    position: relative;
    background-color: #e2e2e2;
}

/* The moving element */
.loading::after {
    display: block;
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    transform: translateX(-100%);
    background: -webkit-gradient(linear, left top,
            right top, from(transparent),
            color-stop(rgba(255, 255, 255, 0.2)),
            to(transparent));

    background: linear-gradient(90deg, transparent,
            rgba(255, 255, 255, 0.2), transparent);

    /* Adding animation */
    animation: loading 1.5s infinite;
}

/* Loading Animation */
@keyframes loading {
    100% {
        transform: translateX(100%);
    }
}

.merchant-list-card {
    -webkit-tap-highlight-color: transparent;
    display: flex;
    margin-bottom: 0px;
    cursor: pointer;
    width: 100%;
    padding: 20px 10px;
    background-color: var(--white-color);
    border-radius: 8px;
}

.merchant-list-card-loading {
    display: flex;
    cursor: pointer;
    width: 100%;
    padding: 10px 15px;
    background-color: var(--white-color);
    border-radius: 8px;
}

.merchant-list-card:last-child {
    margin-bottom: 0;
}

.merchant-list-card img {
    vertical-align: middle;
}

.img-fluid {
    max-width: 100%;
    height: auto;
    vertical-align: middle;
}

.img-container {
    width: 100px;
    height: 100px;
    display: flex;
    border-radius: 10px;
    align-items: center;
    justify-content: center;
    margin-right: 15px;
}

.merchant-list-img {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 10px;
    background: url(../../assets/images/merchant-image.png) center no-repeat;
    position: relative;
    background-size: cover;
    /* display: none; */
}

.merchant-list-img-status {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 10px;
    background-color: black;
    opacity: 0.6;
    background-size: cover;
    /* display: none; */
}

.merchant-list-img-status-promo {
    width: 60px;
    height: 60px;
    object-fit: cover;
    border-radius: 10px;
    background-color: black;
    opacity: 0.6;
    background-size: cover;
    color: white;
    text-align: center;
    padding-top: 18px;
    font-weight: bold;
    font-size: 20px;
    position: absolute;
    border-radius: 40px;
}

.merchant-list-img-merchant {
    width: 110px;
    height: 80px;
    object-fit: cover;
    border-radius: 10px;
    background: url(../../assets/images/merchant-image.png) center no-repeat;
    background-size: cover;
    /* display: none; */
}

.merchant-list-img-merchant-loading {
    width: 110px;
    height: 80px;
    border-radius: 10px;
    background-color: #e2e2e2;
}

.merchant-list-description-wrapper {
    display: flex;
    flex-direction: row;
}

.merchant-list-description-container {
    display: flex;
    flex-direction: column;
    width: 100%;
}


.merchant-list-description-container-arrow {
    display: flex;
    justify-content: center;
    align-self: center;
}

.merchant-list-name,
.merchant-list-desc,
.border {
    margin-bottom: 8px;
}

.merchant-list-name {
    font-size: 20px;
    flex-wrap: wrap;
    font-weight: bold;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box !important;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    white-space: normal;
}

.merchant-list-desc {
    margin-top: 12px;
    font-size: 12px;
    text-overflow: ellipsis;
    flex-wrap: wrap;
    overflow: hidden;
    display: -webkit-box !important;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: normal;
}

.border {
    width: 100%;
    height: 3px;
    background-color: var(--gray-2-color);
}

.merchant-list-detail-container {
    display: flex;
    flex-direction: row;
}

.merchant-detail-list-rating {
    display: flex;
    flex: 1;
}

.merchant-detail-list-address {
    display: flex;
    flex: 10;
}

.merchant-list-detail-container-loading {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.merchant-list-detail {
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box !important;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    white-space: normal;
}

.merchant-detail-list-detail {
    flex-direction: row;
    display: flex;
}

.merchant-list-detail-container span {
    margin-right: 15px;
    font-size: 11px;
}

.merchant-list-detail-icon {
    position: relative;
    bottom: 1.2px;
    font-size: 14px;
}

.merchant-menu-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: nowrap;
    padding: 20px 0px;
    z-index: 2;
    background-color: var(--white-color);
    max-width: 500px;
    min-width: 100px;
    width: 100%;
    bottom: auto;
}

.merchant-menu-container-absolute {
    position: absolute;
}

.merchant-menu-container-fixed {
    z-index: 2;
    position: fixed;
    max-width: 500px;
    min-width: 100px;
    width: 100%;
    background-color: white;
    top:0px;
    /* -webkit-animation: moveDown .2s ease-out forwards; */
}
.merchant-menu-container-absolute{
    position: absolute;
}

.merchant-menu-list-card {
    -webkit-tap-highlight-color: transparent;
    position: relative;
    /* padding: 10px; */
    cursor: pointer;
    width: 100%;
}

.merchant-menu-category {
    padding: 4px;
    border-bottom: 3px solid var(--gray-2-color);
}

.merchant-menu-category-name {
    font-size: 16px;
    font-weight: bold;
    color: var(--black-color);
}

.menu-menu-list-container {
    display: grid;
    grid-template-columns: 50% 50%;
    justify-items: center;
    align-self: center;
}

.merchant-menu-list {
    position: relative;
    display: flex;
    /* background-color: var(--white-color); */
}

.content-merchant-menu-list {
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    border-style: solid;
    border-color: #F5F5F5;
    border-width: 1px 1px 1px 1px;
    background-color: var(--white-color);
}

.content-merchant-menu-list-bottom-title {
    font-size: 13px;
    margin-bottom: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.content-merchant-menu-list-bottom-desc {
    font-size: 12px;
    margin-bottom: 8px;
    color: var(--gray-1-color);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 10px;
}

.content-merchant-menu-list-top {
    flex: 1;
    position: sticky;
}

/* .content-merchant-menu-list-top-img {
    height: 125px;
    width: 150px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.content-merchant-menu-list-top-img-disc {
    height: 125px;
    width: 150px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
} */


.content-merchant-menu-list-bottom {
    flex: 1;
    margin-top: -5px;
    padding: 8px 8px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    box-shadow: 2px 3px 5px 1px rgba(0, 0, 0, 0.08);
    background-color: var(--white-color);
}

.content-merchant-menu-list-bottom-button {
    display: grid;
    flex-direction: row;
    grid-template-columns: 20% 60% 20%;
}

.content-merchant-menu-list-bottom-button .rectangel-minus {
    border-style: solid;
    border-radius: 6px;
    border-color: #EBEBEB;
    border-width: 2px;
    /* padding: 0px 6px;  */
    color: #757575;
    align-self: left;
    background-color: var(--white-color);
}

.content-merchant-menu-list-button-counter-icon {
    font-size: 12px;
}

.content-merchant-menu-list-bottom-button .price-pcs {
    display: flex;
    flex-direction: column;
}

.content-merchant-menu-list-bottom-button .rectangel-plus {
    border-style: solid;
    border-radius: 6px;
    border-color: #EBEBEB;
    border-width: 2px;
    padding: 0px 4px;
    color: #757575;
    align-self: right;
    background-color: var(--white-color);
}

.merchant-menu-img {
    width: 80px;
    height: 80px;
    object-fit: cover;
    border-radius: 10px;
    background: url(../../assets/images/item_image.png) center no-repeat;
    background-size: cover;
}

.merchant-menu-list-detail {
    display: flex;
    flex-direction: column;
}

.merchant-menu-list-detail-name,
.merchant-menu-list-detail-discounted {
    margin-bottom: 15px;
}

.merchant-list-detail-icon {
    vertical-align: middle;
}

.merchant-menu-list-detail-name {
    font-size: 14px;
    font-weight: bold;
    color: var(--black-color);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    display: -webkit-box !important;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.merchant-menu-list-detail-discounted {
    font-size: 12px;
    color: #E50F0F;
}

.merchant-menu-list-detail-price {
    font-size: 12px;
    margin-bottom: 10px;
    color: var(--black-color);
}

.merchant-menu-add-button button {
    width: 50px;
    height: 25px;
    font-size: 14px;
    background-color: transparent;
    border: 1px solid var(--orange-color);
    border-radius: 4px 10px;
    color: var(--orange-color);
    cursor: pointer;
}

.merchant-menu-counter {
    position: absolute;
    right: 0px;
    bottom: 0px;
    z-index: 1;
}

/* .merchant-menu-counter button {
    width: 35px;
    height: 20px;
    font-size: 10px;
    background-color: transparent;
    border: 1px solid var(--orange-color);
    border-radius: 4px 10px;
    color: var(--orange-color);
    cursor: pointer;
} */

.empty-stock-text {
    font-size: 12px;
    color: var(--orange-color);
}

.button-counter {
    width: 35px;
    height: 20px;
    background-color: transparent;
    border: 1px solid var(--orange-color);
    border-radius: 4px 10px;
    color: var(--orange-color);
    cursor: pointer;
    font-size: 12px;
}

.button-add-item {
    width: 45px;
    height: 20px;
    font-size: 10px;
    background-color: transparent;
    border: 1px solid var(--orange-color);
    border-radius: 4px 10px;
    color: var(--orange-color);
    cursor: pointer;
}

.menu-counted {
    margin: 0 9px;
    font-size: 12px;
    color: var(--orange-color);
}

.item-detail-container {
    background-color: var(--gray-2-color);
    min-height: 100vh;
}

.item-detail-main-content {
    padding: 10px;
    /* background-color: var(--gray-2-color);
    min-height: 100vh; */
}

.item-detail-img-container {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-bottom: 10px;
    background-color: var(--gray-2-color);
}

.item-detail-img-container img {
    width: 100%;
    height: 100%;
}

.item-detail-desc {
    position: relative;
    display: flex;
    flex-direction: column;
}

.item-detail-name,
.item-detail-discounted {
    margin-bottom: 6px;
}

.item-detail-name {
    font-weight: bold;
    font-size: 12px;
    width: 85%;
}

.item-detail-price {
    margin-bottom: 30px;
    font-size: 12px;
}

.item-detail-description {
    font-size: 12px;
}

.item-detail-discounted {
    color: #E50F0F;
    font-size: 12px;
}

.item-detail-notes {
    display: flex;
    margin: 30px 0;
    width: 100%;
}

.item-detail-notes input {
    width: 100%;
    height: 50px;
    padding: 0 7px;
    border: 1px solid var(--gray-1-color);
    border-radius: 5px;
    color: var(--black-color);
    font-size: 14px;
}

.item-detail-notes input:focus {
    outline: none;
}

.item-detail-counter {
    position: absolute;
    top: 0px;
    right: 0;
    z-index: 1;
}

.item-detail-counter button {
    width: 60px;
    height: 23px;
    font-size: 14px;
    background-color: transparent;
    border: 1px solid var(--orange-color);
    border-radius: 4px 10px;
    color: var(--orange-color);
    cursor: pointer;
}

.button-counter-icon {
    position: relative;
    top: 1px;
    font-size: 12px;
}

.item-detail-counted {
    margin: 0 9px;
    font-size: 16px;
    color: var(--orange-color);
}

/* CSS added by-azhar*/

.badge {
    position: absolute;
    /* bottom: 0; */
    left: 0;
    top: 0;
    /* width: 100%; */
    text-align: center;
    font-size: 8pt;
    /* margin-top:5px; */
    border-top-left-radius: 8px;
    border-bottom-right-radius: 8px;
    /* border-radius: 20px; */
    background: var(--black-color);
    color: #fff;
    padding: 3px 10px;
}

.badge-np {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    text-align: center;
    font-size: 8pt;
    margin-top: 5px;
    border-radius: 20px;
    background: rgb(238, 64, 20);
    color: #fff;
    padding: 3px 10px;
}

.badge-right {
    bottom: 0;
    left: 0;
    width: 100%;
    text-align: center;
    font-size: 8pt;
    margin-top: 5px;
    border-radius: 20px;
    background: rgb(206, 192, 6);
    color: #fff;
    padding: 3px 10px;
}

.alert-information {
    background: var(--white-color);
    background: var(--white-color);
    padding: 1px;
    background: var(--white-color);    
    padding: 1px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    /* align-items: center; */
    height: auto;    
    padding-bottom: 20px;
}

.alert {
    position: inherit;
    padding: .75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: .25rem
}

.alert-success {
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb
}

.alert-warning {
    color: #856404;
    background-color: #fff3cd;
    border-color: #ffeeba
}



/* Merchant Cart */

.merchant-cart-wrapper {
    padding: 20px 10px 20px 10px;
}

.merchant-cart-content {
    display: flex;
    flex-direction: row;
}

.merchant-cart-content-left {
    display: flex;
    flex-direction: column;
}

.merchant-cart-name {
    font-size: 14px;
    font-weight: bold;
}

.merchant-cart-content-icon {
    font-size: 14px;
}

.merchant-cart-content-left-description {
    margin-top: 5px;
    display: flex;
    flex-direction: row;
}

.merchant-cart-content-left-description img {
    align-self: flex-start;
}

.merchant-cart-content-left-description span {
    margin-left: 5px;
    font-size: 13px;
}

.merchant-cart-content-right {
    justify-content: center;
    align-items: right;
}

.merchant-cart-content-flex-1 {
    flex: 1;
}

.merchant-cart-content-flex-7 {
    flex: 7;
}

/* End of Merchant Cart */

.chip-swiper {
    background-color: #EBEBEB;
    padding: 10px;
    margin: 0px 10px;
    font-size: 12pt;
    border-radius: 22px;
    /* margin-left:10px; */
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    /* align-items: center; */
    /* border-color:rgba(255,201,0,1); */
}

.not-found {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50vh;
    /* background-color: orange;     */
    /* margin: auto; */
}

/* End of CSS added by azhar */

@media (min-width: 0px) and (max-width: 280px) {

    .content-merchant-menu-list {
        display: flex;
        height: 198px;
        width: 120px;
    }

    .content-merchant-menu-list-swiper {
        height: 200px;
        width: 120px;
    }

    .content-merchant-menu-list-top-img-swiper {
        height: 95px;
        width: 120px;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
    }

    .content-merchant-menu-list-top-img-disc,
    .content-merchant-menu-list-top-img {
        height: 95px;
        width: 118px;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
    }
}

@media (min-width: 281px) and (max-width: 320px) {
    .content-merchant-menu-list {
        display: flex;
        height: 208px;
        width: 130px;
    }

    .content-merchant-menu-list-swiper {
        height: 210px;
        width: 130px;
    }

    .content-merchant-menu-list-top-img-swiper {
        height: 105px;
        width: 130px;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
    }

    .content-merchant-menu-list-top-img-disc,
    .content-merchant-menu-list-top-img {
        height: 105px;
        width: 128px;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
    }
}

@media (min-width: 321px) and (max-width: 375px) {
    .content-merchant-menu-list {
        display: flex;
        height: 228px;
        width: 152px;
    }

    .content-merchant-menu-list-swiper {
        height: 230px;
        width: 152px;
    }

    .content-merchant-menu-list-top-img-swiper {
        height: 125px;
        width: 152px;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
    }

    .content-merchant-menu-list-top-img-disc,
    .content-merchant-menu-list-top-img {
        height: 125px;
        width: 150px;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
    }
}

@media (min-width:376px) and (max-width:424px) {
    .content-merchant-menu-list {
        display: flex;
        height: 250px;
        width: 177px;
    }

    .content-merchant-menu-list-swiper {
        height: 250px;
        width: 177px;
    }

    .content-merchant-menu-list-top-img-swiper {
        height: 150px;
        width: 178px;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
    }

    .content-merchant-menu-list-top-img-disc,
    .content-merchant-menu-list-top-img {
        height: 150px;
        width: 175px;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
    }
}

@media (min-width:425px) and (max-width:500px) {
    .content-merchant-menu-list {
        display: flex;
        height: 278px;
        width: 192px;
    }

    .content-merchant-menu-list-swiper {
        height: 220px;
        width: 132px;
    }

    .content-merchant-menu-list-top-img-disc {
        height: 110px;
        width: 130px;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
    }
    
    .content-merchant-menu-list-top-img-swiper {
        height: 110px;
        width: 130px;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
    }

    .content-merchant-menu-list-top-img {
        height: 145px;
        width: 190px;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
    }

}

@media (min-width:501px) {

    .component-description.loading,
    .component-title.loading {
        width: 300px;
    }

    .content-merchant-menu-list {
        display: flex;
        height: inherit;
        width: 222px;
    }

    .content-merchant-menu-list-swiper {
        height: 230px;
        width: 150px;
    }

    .content-merchant-menu-list-top-img-disc {
        height: 125px;
        width: 150px;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
    }    

    .content-merchant-menu-list-top-img-swiper {
        height: 125px;
        width: 150px;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
    }  

    .content-merchant-menu-list-top-img {
        height: 195px;
        width: 220px;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
    }    
}


.button-add-item-center {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 5px 0px;
    box-sizing: border-box;
    border-radius: 6px;
    background-color: rgba(255, 201, 0, 1);
    width: '100%';
    height: 30px;
}

.button-add-item-center-disabled {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 5px 0px;
    box-sizing: border-box;
    border-radius: 6px;
    background-color: rgba(255, 200, 0, 0.5);
    width: '100%';
    height: 30px;
}

.text-selling-price {
    font-size: 14px;
    font-weight: 400;
    line-height: 129%;
    color: rgba(33, 33, 33, 1);
    word-wrap: break-word;
    margin-right: 4px;
}

.text-sold-out {
    font-size: 14px;
    font-weight: 400;
    line-height: 129%;
    color: rgba(33, 33, 33, 0.5);
    word-wrap: break-word;
    margin-right: 4px;
}

.text-actual-price {
    font-size: 10px;
    font-weight: 400;
    line-height: 160%;
    color: rgba(33, 33, 33, 1);
    word-wrap: break-word;
    text-decoration: line-through;
}

.discount-item-container {
    margin: 10;
    /* background-color: transparent; */
    width: 100%;
}

.merchant-icon-info {
    margin-right: 5px;
}

.merchant-info-location {
    text-overflow: ellipsis;
    flex-wrap: wrap;
    overflow: hidden;
    display: -webkit-box !important;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.item-details-modal-container,
.merchant-details-modal-container {
    width: 100%;
    max-width: 500px;
}

.item-details-modal-container-open,
.merchant-details-modal-container-open {
    height: 100%;
    width: 500px;
    position: fixed;
    z-index: 3;
    background-color: rgba(0, 0, 0, 0.5);
    /* overflow: hidden; */
    /* transition: opacity 5000ms ease 0s; */
}

.item-details-modal-container-close,
.merchant-details-modal-container-close {
    height: 100%;
    width: 500px;
    position: fixed;
    z-index: 3;
    background-color: rgba(0, 0, 0, 0.5);
    /* overflow: hidden; */
    visibility: hidden;
    /* transition: opacity 5000ms ease 0s; */
}

.transitionUp {
    z-index: 3;
    width: inherit;
    position: fixed;
    bottom: 0;
    transition: height 400ms ease-in-out 0s;
}

.transitionDown {
    z-index: 3;
    height: 0%;
    width: inherit;
    position: fixed;
    bottom: 0;
    transition: height 400ms ease-in-out 0s;
}

.item-transition {
    height: 75%;
}

.merchant-transition {
    /* min-height: 30%; */
    /* height: auto; */
    height: 40%;
    /* max-height: 70%; */
    overflow-y: scroll;
}

.merchant-transition::-webkit-scrollbar,
.container-merchant-details::-webkit-scrollbar,
.container-item-details::-webkit-scrollbar {
    width: 0px;
}

.item-details-container {
    width: 500px;
}

.container-item-details,
.container-merchant-details {
    margin-top: 20;
    background-color: var(--white-color);
    height: 100%;
    width: 100%;
    display: flex;
    /* padding: 20px; */
    flex-direction: column;
    top: 0;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    overflow-y: scroll;
    scrollbar-width: 0px;

}

.container-merchant-details {
    padding: 32px 0px;
}

.content-merchant-share-header {    
    display: flex;
    flex-direction: row;
    padding-bottom: 30px;
}

.content-merchant-share-header-text {
    flex: 1;
    display: flex;
    justify-content: center;
    font-weight: bold;
}

.content-merchant-share-body {}

.content-merchant-share-body-top {
    flex: 1;
    display: flex;
    flex-direction: row;
    overflow: scroll;
    /* background-color: #517C92; */
}

.content-merchant-share-body-top::-webkit-scrollbar {
    display: none;
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.content-merchant-share-body-component {
    padding: 10px;
    flex: 1;
    display: flex;
    flex-direction: column;
}

.share-merchant-icon {
    flex: 1;
    display: flex;
    justify-content: center;
}

.share-merchant-text {
    display: flex;
    justify-content: center;
    font-size: 10pt;
}

.content-merchant-share-body-bottom {
    padding: 10px;
}

.content-merchant-share-body-bottom-wrapper {
    padding: 10px;
    flex: 1;
    width: 100%;
    border-radius: 5px;
    border: 2px solid #EBEBEB;
    display: flex;
}

.content-merchant-share-footer-copy {
    cursor: pointer;
    display: flex;
    justify-content: center;
}

.content-merchant-share-body-bottom-wrapper input {
    flex: 1;
    border: 0px;
}

.content-merchant-share-body-bottom-wrapper input:focus {
    outline: none;
}

.photo-item-details-container {
    width: 100%;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
}

@media (min-width:500px) {

    .item-details-modal-container,
    .merchant-details-modal-container {
        width: 500px;
    }
}


.group-input {
    position: relative;
    padding: 15px 0 0;
    margin: 16px;
    width: calc(100%-16px);
}

.input-field {
    font-family: inherit;
    width: 100%;
    border: 0;
    border-bottom: 2px solid #9b9b9b;
    outline: 0;
    font-size: 16px;
    font-weight: 400;
    /* color: black; */
    padding: 7px 0;
    padding-left: 8px;
    background: transparent;
    transition: border-color 0.2s;
}

.input-field::placeholder {
    color: transparent;
}

.input-field:placeholder-shown~.input-label {
    font-size: 1.3rem;
    cursor: text;
    top: 20px;
}

.input-label {
    position: absolute;
    top: 0;
    display: block;
    transition: 0.2s;
    font-size: 16px !important;
    color: #969696;
    font-weight: 400;
    padding-left: 8px;
}

.input-field:focus {
    padding-bottom: 6px;
    font-weight: 400;
    border-width: 3px;
    border-image: linear-gradient(to right, #517C92, #517C92);
    border-image-slice: 1;
}

.input-field:focus~.input-label {
    position: absolute;
    top: 0;
    display: block;
    transition: 0.2s;
    font-size: 12px;
    color: #517C92;
    font-weight: 400;
}

/* reset input */
.input-field:required,
.input-field:invalid {
    box-shadow: none;
}

.text-button-addToCart-details {
    font-size: 16px;
    /* font-family: Roboto, sans-serif; */
    font-weight: 500;
    color: rgba(33, 33, 33, 1);
    text-align: center;
    word-wrap: break-word;
    /* justify-content: center; */
    /* background-color: salmon; */
}

.item-details-modal-button-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 16px;
    /* background-color: red; */
}

.item-details-modal-button-wrapper {
    display: flex;
    flex-direction: row;
    width: 164px;
    height: 51px;
    justify-content: center;
    align-items: center;
}

.minus-plus-button {
    border: 1px solid #EBEBEB;
    background-color: transparent;
    color: #757575;
    padding: 4px 8px;
    border-radius: 4px;
}

.minus-plus-button-readonly {
    border: 1px solid #EBEBEB;
    background-color: transparent;
    color: #EBEBEB;
    padding: 4px 8px;
    border-radius: 4px;
}

.item-details-modal-quantity {
    flex: 2;
    display: flex;
    flex-direction: column;
    text-align: center;
}

.item-details-modal-text {
    flex: 1;
    font-size: 16px;
}

.item-details-modal-text-gray {
    flex: 1;
    font-size: 10px;
    color: var(--gray-1-color);
}

.container-text-button-addToCart-details {
    /* padding: 15px 102px; */
    box-sizing: border-box;
    border-radius: 8px;
    background-color: rgba(255, 201, 0, 1);
    width: 164px;
    height: 51px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition-duration: 0.4s;
    text-decoration: none;
    overflow: hidden;
    cursor: pointer;
}

.container-text-button-addToCart-details:active {
    background-color: rgba(255, 201, 0, 0.3);
    transition: all 0.2s
}

.disabled-addToCart-details {
    background-color: rgba(255, 201, 0, 0.5);
}

img::before {
    background-image: url("https://id-marketplace.sgp1.cdn.digitaloceanspaces.com/files/merchant/70Ht3pFljemj63FACWmkjUitnLzBKHFo0RtzzYoz.png");
}

.merchant-bottom-space {
    height: 60px;
    background-color: transparent;
}

.item-details-text-name {
    font-size: 20px;
    font-weight: 700;
    padding: 16px;
}

.item-details-text-price {
    font-size: 16px;
    font-weight: 400;
    margin-top: 16px;
    margin-right: 16px;
}

.item-details-text-disc-price {
    font-size: 10px;
    font-weight: 400;
    text-decoration-line: line-through;
    height: 20px;
    text-align: right;
    margin-right: 16px;
}

.item-details-text-desc {
    margin: 0px 0px 13px 13px;
    padding: 3px;
    width: 262px;
    font-size: 14px;
    font-weight: 400;
    color: var(--gray-1-color);
}

/* loading */
.content-merchant-menu-list-loading {
    margin: 10px;
    height: 255px;
    width: 177px;
    border-radius: 8px;
    background-color: #e2e2e2;
}

.merchant-menu-category-loading {
    display: flex;
    flex-direction: row;
    margin-left: 10px;
    margin-right: 10px;    
}

.merchant-menu-category-loading-component {    
    background-color: #e2e2e2;
    margin-left: 5px;
    margin-right: 5px;
    /* border: 1px solid rgba(255,201,0,1); */
    border-radius: 16px;
    width: 90px;
    height: 32px;    
}

@media (min-width: 0px) and (max-width: 320px) {
    .content-merchant-menu-list-loading {
        height: 200px;
        width: 120px;
    }
}

@media (min-width: 0px) and (max-width: 320px) and (max-height: 480px) {
    .merchant-transition {
        height: 60%;
    }
}

@media (min-width: 0px) and (max-width: 320px) and (min-height: 500px) {
    .merchant-transition {
        height: 45%;
    }
}

@media (min-width: 321px) and (max-width: 375px) {
    .content-merchant-menu-list-loading {
        height: 210px;
        width: 130px;
    }

    .merchant-transition {
        height: 45%;
    }
}

@media (min-width:376px) and (max-width:424px) {
    .content-merchant-menu-list-loading {
        height: 255px;
        width: 177px;
    }

    .merchant-transition {
        height: 35%;
    }
}

@media (min-width:425px) and (max-width:2000px) and (max-height: 799px) {
    .content-merchant-menu-list-loading {
        height: 255px;
    }

    .merchant-transition {
        height: 40%;
    }
}

@media (min-width:425px) and (max-width:2000px) and (min-height: 800px) {
    .content-merchant-menu-list-loading {
        height: 255px;
        width: 177px;
    }

    .merchant-transition {
        height: 30%;
    }
}