.explore-merchant-list-container {

}

.explore-result-container {
    width: 100%;
    /* margin-bottom: 30px; */
}

.explore-merchant-list-card {
    -webkit-tap-highlight-color: transparent;
    display: flex;
    margin-bottom: 0px;
    cursor: pointer;
    width: 100%;
    padding: 20px 10px;
    background-color: var(--white-color);
    border-radius: 8px;
}

.explore-merchant-list-card:last-child {
    margin-bottom: 0;
}

.explore-merchant-list-card img {
    vertical-align: middle;
}

.img-container {
    width: 100px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 15px;
}

.explore-merchant-img {
    width: 80px;
    height: 80px;
    object-fit: cover;
    border-radius: 10px;
}

.explore-merchant-detail {
    display: flex;
    flex-direction: column;
    border-bottom: 2px solid var(--gray-2-color);
}

.explore-merchant-detail span:last-child {
    margin-bottom: 0;
}

.explore-merchant-name {
    font-size: 20px;
    margin-bottom: 20px;
    color: var(--gray-1-color);
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    display: -webkit-box !important;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.explore-merchant-desc {
    font-size: 13px;
    color: var(--gray-1-color);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    display: -webkit-box !important;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.explore-result-type {
    /* margin: 10px; */
    font-weight: bold;
}

.explore-result-item-card {
    margin: 20px;
}

.explore-result-merchant-name-text {
    font-weight: bold;
    font-size: 12px;
}

#orange-color {
    color: var(--orange-color);
}

@media screen and (max-width: 425px) {merchantsmerchants   

    .explore-merchant-name {
        font-size: 17px;
        margin-bottom: 10px;
    }

    .explore-merchant-desc {
        font-size: 12px;
    }
}